<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<el-button type="info" @click="reset">重置</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
					<!-- <el-button type="primary" @click="goaddnew">新增</el-button> -->
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="340" @getdata="gettable">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="look(scope.row)" type="success" size="mini">详情</el-button>
							<el-button v-show="scope.row.zddbta==1" type="primary" size="mini" @click="setzd(scope.row,1)">设为推荐重点督办</el-button>
							<el-button v-show="scope.row.zddbta==2" type="warning" size="mini" @click="setzd(scope.row,2)">取消推荐重点督办</el-button>
							<el-button v-show="scope.row.zddbta==2" type="primary" size="mini" @click="setzd(scope.row,3)">确定重点督办</el-button>
							<el-button v-show="scope.row.zddbta==3" type="warning" size="mini" @click="setzd(scope.row,4)">取消确定重点督办</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>


		<!--详情-->
		<mydetail v-if="showdetail" :id="detail_id" :showdy="true" requsturl="/api/proposa/details"
			@closedetail="showdetail=false">			
		</mydetail>
		
		<!--设为重点督办-->
		<el-dialog title="重点督办" :visible.sync="showdb" :before-close="closedb" width="30%">
			<div style="width: 100%;">
				<div style="margin-bottom: 10px;">请选择督办领导：</div>
				<el-select v-model="zhuanren" filterable remote reserve-keyword placeholder="请输入名字搜索" style="width: 100%;"
					:remote-method="remoteMethod" :loading="loading">
					<el-option v-for="item in userlist" :key="item.id" :label="item.name" :value="item.id">
						<span>{{ item.name }}---</span>
						<span>{{ item.phone }}</span>
					</el-option>
				</el-select>
			</div>
		
			<span style="width: 100%;display: flex;align-items: center;justify-content: flex-end;margin-top: 20px;">
				<el-button @click="closedb" size="small">取消</el-button>
				<el-button type="success" @click="qrdb()" size="small">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	import mydetail from "@/components/detail_tc.vue";
	export default {
		components: {
			mydetail,
			Editor,
			Toolbar
		},
		data() {
			return {
				showdetail: false,
				detail_id: '',
				searchinput: '',
				tableHeader: [{
						name: '标题',
						prop: 'name',
						width: '300'
					},
					{
						name: '提案类别',
						prop: 'lei_name',
						width: ''
					},
					{
						name: '提交人',
						prop: 'user_name',
						width: ''
					},
					{
						name: '提交时间',
						prop: 'addtime',
						width: ''
					},
					{
						name: '状态',
						prop: 'type',
						width: '120',
						type: 'tags'
					},
					{
						name: '是否为建议',
						prop: 'sfyjtype',
						width: '100',
						type: 'tags_isjy'
					},
					{
						name: '是否重点督办',
						prop: 'zddbta',
						width: '140',
						type: 'tags_zddbta'
					},
					{
						name: '督办领导',
						prop: 'dbuser_name',
						width: '140'
					},
				],
				tableData: [],
				showdb:false,
				loading: false,
				zhuanren:'',
				userlist:[],
				iii:1,
				czdata:''
			}
		},
		mounted() {
			this.gettable()
		},
		methods: {
			closedb(){
				this.zhuanren = ''
				this.showdb = false
			},
			qrdb(){
				console.log(this.zhuanren)
				this.$post({
					url: '/api/proposa/qtcz',
					params: {
						ids:this.czdata.id,
						dbuser:this.zhuanren,
						zddbta:this.iii==1?2:this.iii==2?1:this.iii==3?3:this.iii==4?2:1//i--1 设为推荐重点督办、2 取消推荐重点督办、3 确定重点督办、4 取消确定重点督办
					}
				}).then((res) => {
					this.closedb()
					this.$message.success('操作成功')
					this.gettable()
				})
			},
			remoteMethod(query) {
				if (query !== '') {
					this.loading = true;					
					//获取提案类型
					this.$post({
						url: '/api/user/index',
						params: {
							name:query,
							p: 1,
							size: 9999
						}
					}).then((res) => {
						this.loading = false;
						this.userlist = res.list
					})
				} else {
					this.userlist = [];
				}
			},
			setzd(item,i){
				if(item.dbuser){
					this.remoteMethod(item.dbuser_name)
					this.zhuanren = item.dbuser
				}
				this.iii = i
				this.czdata = item
				//i--1 设为推荐重点督办、2 取消推荐重点督办、3 确定重点督办、4 取消确定重点督办
				if(i==2||i==4){
					this.$post({
						url: '/api/proposa/qtcz',
						params: {
							ids:this.czdata.id,
							dbuser:i==2?'':item.dbuser,
							zddbta:this.iii==1?2:this.iii==2?1:this.iii==3?3:this.iii==4?1:1
						}
					}).then((res) => {
						this.closedb()
						this.$message.success('操作成功')
						this.gettable()
					})					
					return
				}
				this.showdb = true
				
			},
			look(item) {
				this.rowdata = item
				this.detail_id = item.id
				this.showdetail = true
			},
			goaddnew() {
				this.$router.push('/system/add_zhengji')
			},
			addnew() {
				this.$router.push('/system/swiper_add')
			},
			reset() {
				this.searchinput = ''
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/proposa/index',
					params: {
						zddbta:'2,3',
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {
					res.list.forEach((item, index) => {
						let cbdw_type = 1 //1未答复、2已答复、3已办理、4、有异议--提案 5、采纳--意见，6、不采纳--意见
						if (item.dfhtype == 1 && item.state == 2) {
							cbdw_type = 4
						} else if (item.dfhtype != 1) {
							cbdw_type = item.dfhtype
						} else if (item.dfhtype == 1 && item.sfcn == 0) {
							cbdw_type = 1
						} else if (item.dfhtype == 1 && item.sfcn == 1) {
							cbdw_type = 5
						} else if (item.dfhtype == 1 && item.sfcn == 2) {
							cbdw_type = 6
						}
						item.cbdw_type = cbdw_type
					})
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			deleterow(row) {
				this.$confirm('确认删除该条数据吗？')
					.then(_ => {
						this.$post({
							url: '/admin/banner/delete',
							params: {
								id: row.id
							}
						}).then((res) => {
							this.$message.success('删除成功')
							this.gettable()
						})
					})
					.catch(_ => {});

			},
			showsetrole(row) {
				console.log(row)
				this.$refs.mymx.showmx = true
			},
			changswitch(row) {
				console.log(row)
			},
			showedit(row) {
				this.$router.push({
					path: '/system/swiper_edit',
					query: {
						id: row.id
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 25px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.8rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {}

	::v-deep .el-table td.el-table__cell {}

	::v-deep .el-table {
		margin-top: 10px;

	}

	::v-deep .el-table__fixed {}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}
</style>